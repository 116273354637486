import React from "react";
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "@components/layout"
import Seo from "@components/seo"
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"
import parse from "html-react-parser"

// コンポーネント呼び出し
import { CampaignGraphqlArray } from "@components/graphql-data-campaign.js"; // 記事情報取得・形成
import { FixedFoot } from "@components/footer";

const CampaignPost = ({ data: { post, allWpPost, wp }, pageContext }) => {
  // const contentEl = useRef();
  const indexUrl = '/';
  const typeUrl = 'campaign/';
  const name = pageContext.name; // 該当記事名
  const slug = pageContext.slug; // 該当Slug
  // const searchList = SearchCategList().Main; // カテゴリ検索リスト(components/search-categList.js)
  const allPostData = CampaignGraphqlArray('', 'allandMain');
  const allData = allPostData.allData;
  // const mainCategs = allPostData.mainCateg;

  const contentData = post.content;
  

  // 該当記事情報取得(components/graphql-data-campaign.js)
  let setArray;
  for (let i = 0; i < allData.length ; i++){
    if (allData[i].Title === name){
      setArray = allData[i]; 
    }
  }

  // 表示データ
  const date = setArray.Date; // 作成日
  const modified = setArray.Modified; // 更新日
  const featuredImage = { // 記事画像
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
  }

  // 大カテゴリ、中カテゴリ選定
  // let setMainCateg, setMainCategSlug, setSubCateg, result;
  // for (let i = 0; i < setArray.Categories.length ; i++){
  //   result = mainCategs.find(item => item === setArray.Categories[i])
  //   if ( result === setArray.Categories[i] ) {
  //     setMainCateg = setArray.Categories[i]
  //     setMainCategSlug = setArray.CategsSlug[i]
  //   } else {
  //     setSubCateg = setArray.Categories[i]
  //   }
  // }
    
  const fixedItem = FixedFoot( 'nomal', '' ); // 固定フッター
  // const faqJsonData = FaqComponent( setSubCateg, setArray.Tags, slug ).jsonData; // faqの構造化データ
  // const textContents = SsComponent( contentData, idArray, hrefArray ); // 本文情報とid情報引き渡し  
  
  return (
  <>
    <Layout>
      <Seo
        title={post.title}
        description={setArray.Description}
        ogpUrl={process.env.ORIGINURL + indexUrl + slug + '/'}
        ogpSiteName={post.title}
        ogpImage={post.featuredImage?.node?.guid || ''}
        ogpImageWidth={post.featuredImage?.node?.width || ''}
        ogpImageHeight={post.featuredImage?.node?.height || ''}
        ogpImageType={post.featuredImage?.node?.mimeType || ''}
        modifiedDate={post.modified || ''}
        publishedDate={post.date || ''}
        wpPost = "true"
      />
      
      <article className="blog-post" itemScope itemType="http://schema.org/Article">

      <div className="bg-[#F6F6F6] md:pb-[45px] pb-2">
        <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
          <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
            <h2 className="md:text-5xl text-3xl font-black mb-5 text-left pt-5 !leading-[1.2]">キャンペーン</h2>
            <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">Just tradeのお得なキャンペーン情報をお届けします。</h3>
          </div>
        </div>

        </div>


        <div className="md:max-w-5xl md:flex justify-between mx-auto pt-5 md:pt-12"> 
          <div className="max-w-[730px] w-full pb-5 px-5 mb-10 md:pb-0 md:mb-0">
            
            {/* タイトル */}
            <div className="text-left mb-10">
              <h2 className=" font-semibold tracking-wide text-[20px] md:text-[26px] text-[#1F55A6] table" itemProp="headline">{ name }</h2>
            </div>

            {/* 作成日、更新日 */}
            <p className="text-[11px] mb-3 flex ">
              <span className="test100 px-2">作成日：{date}</span>
              <span className="px-2">更新日：{modified}</span>
            </p>

            {/* 画像 */}
            <div>
              <div className="mt-0  rounded-blog max-w-[730px] w-full" >
                {featuredImage?.data && (
                  <GatsbyImage className=" w-full max-w-[730px]" image={featuredImage.data} alt={featuredImage.alt}/>   
                )}
              </div>
            </div>

            {/* 本文 */}
            <ol>
              <div className="md:my-0 md:mx-auto" style={{ paddingTop: 24, paddingBottom: 24, maxWidth: 976}}>
                {!!post.content && (
                  <section itemProp="articleBody">{parse(contentData)}</section>
                )}
              </div>
            </ol>
            
           {/* ページネーション */}
            <ol>
              <div className="bg-[#F8F8F8] rounded-blog mt-10 mb-[60px]">
                <a href={indexUrl + typeUrl } className="flex px-5 py-5  md:text-sm">
                  <nav className="blog-post-nav w-full my-0 mx-auto" style={{ maxWidth: 976 }}>
                    <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', listStyle: 'none', padding: 0 }}>
                      記事一覧へ戻る
                    </ul>
                  </nav>
                </a>
              </div>
            </ol>
  
          </div>
        </div>
        <div>
          {/*ワード検索 */}
         
          {/* MBプロフィール */}
          
        </div>
      </article>

      {/* 固定フッター */}
      {fixedItem}
    </Layout>
  </>
  )
}
export default CampaignPost

export const pageQuery = graphql`
  query CampaignPostById(
    $id: String!
  ) {
    allWpCampaign(
      sort: { fields: date, order: DESC }
    ) {
      pageInfo {
        totalCount
      }
      edges {
        node {
          id
          uri
          title
          campaignCategory {
            nodes {
              name
            }
          }
          campaignTag {
            nodes {
              name
            }
          }
        }
      }
    }
    post: wpCampaign(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      modified
      campaignCategory {
        nodes {
          name
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          guid
          mimeType
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`